import MapContainer from "../components/MapContainer";

function Map() {
    return (
        <div>
            <MapContainer/>
        </div>
    );
}

export default Map;
