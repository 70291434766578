import SearchForm from "../components/SearchForm";

function Search() {
    return (
        <>
            <SearchForm/>
        </>
    );
}

export default Search;
