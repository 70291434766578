import FavsList from "../components/FavsList";

function Favs() {
    return (
        <div>
            <FavsList/>
        </div>
    );
}

export default Favs;
